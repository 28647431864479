import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './HomePage.css'
import projects from '../../img/projects.jpg'
import projCover from '../../img/projCover.jpg'
import speakersSession from '../../img/SpeakersSession.jpg'
import photographyCover from '../../img/photographyCover.jpg'
import CarouselHomePage from './CaourselHomePage';


function HomePage() {
    return (

        <div>
            <div className='helloDivider'>
                <h2 className='hello helloTitleBold'>
                    Hello,
                </h2>
                <h2 className='hello helloTitleBold'>
                    I'm James
                </h2>
                <hr className='HRStyling' />
            </div>


            <div className='restrictWidth'>
                <div className='carouselContainer'>
                    <CarouselHomePage />
                    <hr />
                </div>
            </div>

            <div className='restrictWidth'>
                <Row lg={3} md={2} xs={1}>
                    <Col>
                        <Card className='cardSpacing'>
                            <Card.Link href='/projects' className="cardLink">
                                <Card.Img variant="top" src={projCover} />
                                <Card.Body>
                                    <Card.Title>Projects!</Card.Title>
                                    <Card.Text>
                                        Useful bits of code that I've made to help me do my job, solves problems I've seen or made just for the fun of it!
                                    </Card.Text>
                                </Card.Body>
                            </Card.Link>
                        </Card>
                    </Col>

                    <Col >
                        <Card className='cardSpacing'>
                            <Card.Link href='/blogs+tutorials' className="cardLink">
                                <Card.Img variant="top" src={speakersSession} />
                                <Card.Body>
                                    <Card.Title>Blog posts & Tutorials</Card.Title>
                                    <Card.Text>
                                        I love teaching, mostly because sometimes by writing up problems I've solved, it helps me understand it better and improve the process.
                                        And if I can save the next person the hours of googling and troubleshooting I had to go through, all the better!
                                    </Card.Text>
                                </Card.Body>
                            </Card.Link>

                        </Card>
                    </Col>


                    <Col >
                        <Card className='cardSpacing'>
                        <Card.Link href='/photography' className="cardLink">

                            <Card.Img variant="top" src={photographyCover} />
                            <Card.Body>
                                <Card.Title>Photography</Card.Title>
                                <Card.Text>
                                    My favourite hobby that gets me away from the computer screen and out to the real world
                                </Card.Text>
                            </Card.Body>
                            </Card.Link>

                        </Card>
                    </Col>
                </Row>
            </div>

            <div className='helloDivider'>
                <hr />
            </div>
        </div>

    );
}

export default HomePage;